import React, { useContext, useEffect, useState } from 'react';
import './Gallery.css';
import { Col, Container, Row } from 'react-bootstrap';
import FaqBanner from '../Faq/FaqBanner/FaqBanner';
import BreadcrumComponent from '../CommanComponent/BreadcrumComponent/BreadcrumComponent';
import Pagination from 'react-bootstrap/Pagination';
import GallerImageModal from '../CommanComponent/CommanModal/GallerImageModal/GallerImageModal';
import { getgallery } from '../../utils/apis/master/Master';
import { Context } from '../../utils/context';

const Gallery = () => {
    const { IMG_URL } = useContext(Context);

    const [modalGallery, setModalGallery] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [gallery, setGallery] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPages, setPerPages] = useState(9); // Default per page items

    const breadcrumbItems = [
        { text: "HOME", link: "/" },
        { text: "GALLERY", link: "/gallery", className: "active" },
    ];

    const fetchGallery = async (page, perPage) => {
        try {
            const res = await getgallery(page, perPage);
            if (res?.success) {
                setGallery(res?.results || []);
                setTotalPages(res?.totalPages || 1);
                setCurrentPage(res?.currentPage || 1);
            } else {
                console.error("Error fetching data:", res?.error);
            }
        } catch (error) {
            console.error("Error fetching gallery data:", error);
        }
    };

    useEffect(() => {
        fetchGallery(currentPage, perPages); // Fetch data whenever currentPage changes
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setModalGallery(true);
    };

    return (
        <>
            <FaqBanner HeadingName={"Gallery"} />
            <section className='gallery'>
                <Container>
                    <Row>
                        <div className='my-5'>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                        {gallery?.map((item, index) => (
                            <Col lg={4} md={6} key={index}>
                                <div className='gallery-image mb-4' onClick={() => openModal(index)}>
                                    <img
                                        className="all-galley-img"
                                        src={IMG_URL + item.image}
                                        alt="gallery"
                                    />
                                </div>
                            </Col>
                        ))}
                        <Col lg={12} className='blog'>
                            <Pagination className=''>
                                <Pagination.Prev
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </Pagination.Prev>
                                {[...Array(totalPages)].map((_, idx) => (
                                    <Pagination.Item
                                        key={idx + 1}
                                        active={idx + 1 === currentPage}
                                        onClick={() => handlePageChange(idx + 1)}
                                    >
                                        {idx + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </Pagination.Next>
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </section>
            <GallerImageModal
                show={modalGallery}
                onHide={() => setModalGallery(false)}
                galleryData={gallery} // Pass fetched gallery data
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
            />
        </>
    );
};

export default Gallery;
