import React, { useRef, useState, useEffect } from "react";
import BreadcrumComponent from "../CommanComponent/BreadcrumComponent/BreadcrumComponent";
import CategoryTabSection from "../Services_category/CategoryTabSection/CategoryTabSection";
import { Col, Container, Form, Row, Tab } from "react-bootstrap";
import FaqBanner from "../Faq/FaqBanner/FaqBanner";
import "./ProductDetails.css";
import CommanButton from "../CommanComponent/CommanButton/CommanButton";
import Select from "react-select"; // Import react-select
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "react-phone-input-2/lib/bootstrap.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import { Link } from "react-router-dom";
import {
  ContactusPost,
  CountryWithout,
  CityWithout,
} from "../../utils/apis/master/Master";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Controller, SubmitHandler } from "react-hook-form";
import { Select2Data } from "../../utils/common";
import SuccessLottey from "../CommanComponent/SuccessLottey/SuccessLottey";

const ProductDetails = () => {
  const [selectedTab, setSelectedTab] = useState("first");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const formRef = useRef(null);
  const headingRef = useRef(null);
  // const handleSubmit = () => {
  //   setShowSuccessMessage(true);
  //   setTimeout(() => {
  //     setShowSuccessMessage(false);
  //     // Redirect to home page after 10 seconds
  //     window.location.href = "/services-category";
  //   }, 3000); // 10 seconds
  // };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const handleTabSelect = (eventKey) => {
    setSelectedTab(eventKey);
  };

  const handleEnquiryClick = () => {
    if (headingRef.current) {
      headingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const breadcrumbItems = [
    { text: "HOME", link: "/" },
    { text: "SERVICES", link: "/services" },
    { text: "GEARS", link: "/Gears" },
    { text: "BEVEL GEAR", link: "", className: "active" },
  ];
  const countryOptions = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "#F3F3F3",
      borderRadius: "5px",
      fontSize: "14px",
      border: "none",
      height: "40px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d" /* Bootstrap's default placeholder color */,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057" /* Bootstrap's default input text color */,
    }),
  };
  return (
    <>
      <FaqBanner HeadingName={"Gears"} />
      <section className="product-details">
        <Container>
          <div className="my-5">
            <BreadcrumComponent items={breadcrumbItems} />
          </div>
          <Tab.Container
            id="left-tabs-example"
            activeKey={selectedTab}
            onSelect={handleTabSelect}
          >
            <Row>
              <Col lg={3}>
                <CategoryTabSection onSelect={handleTabSelect} />
              </Col>
              <Col lg={9}>
                <Row>
                  <Col lg={4}>
                    <div className="product-image-card">
                      <div className="product-icon text-center">
                        <img
                          className="product-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/services/Gear1.png"
                          }
                        />
                      </div>
                      <div className="button-holder">
                        <CommanButton
                          className={"blue-btn"}
                          text={"Enquiry"}
                          onClick={handleEnquiryClick}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="details-text">
                      <h2>Bevel Gear</h2>
                      <p>(Product Code : LK BGear 2024)</p>
                      <span>
                        Bevel gears are specialized gears designed to transmit
                        motion between intersecting shafts efficiently. They
                        feature angled teeth that allow for smooth and quiet
                        operation, making them ideal for applications requiring
                        precise torque transfer and motion control. Used in
                        various industries such as automotive, aerospace, and
                        machinery, bevel gears come in different configurations
                        to suit specific operational requirements, from straight
                        bevel gears for simple applications to spiral and hypoid
                        bevel gears for increased load capacity and efficiency.
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <div className="text-holder mb-lg-5 mb-md-5 mb-sm-3  mb-3 border-line">
                      <h3>Warranty</h3>
                      <h4>1 Year Return & Exchange</h4>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="text-holder  mb-lg-5  mb-md-5 mb-sm-3 mb-3 border-line">
                      <h3>Life Span</h3>
                      <h4>Over 15000 hrs</h4>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="text-holder mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                      <h3>Customized Service</h3>
                      <h4>Size & Solution</h4>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="text-holder mb-3">
                      <h2>High-Speed Applications</h2>
                      <p>
                        Reducing noise levels in right-angle applications
                        typically centers around optimizing the meshing distance
                        between gears. This approach is particularly beneficial
                        for spiral bevel gears, ensuring precise alignment and
                        minimizing backlash when calibrated accurately. Such
                        measures are essential for enhancing control over
                        transmission efficiency and can be crucial in
                        environments where space constraints necessitate noise
                        reduction techniques.
                      </p>
                    </div>
                    <div className="text-holder mb-3">
                      <h2>Noise Reduction And High Torque</h2>
                      <p>
                        Conversely, bevel gears offer higher torque capabilities
                        compared to alternative gear types, prioritizing
                        practical objectives and facilitating quicker
                        adjustments. In industrial settings, they guarantee
                        robust force transmission & durability, providing
                        optimal solutions for achieving greater output
                        efficiency in minimal time. Therefore, ensuring
                        effective noise reduction while maximizing operational
                        uptime remains essential for bevel gear performance.
                      </p>
                    </div>
                    <div className="text-holder mb-3">
                      <h2>High-Speed Applications</h2>
                      <p>
                        Reducing noise levels in right-angle applications
                        typically centers around optimizing the meshing distance
                        between gears. This approach is particularly beneficial
                        for spiral bevel gears, ensuring precise alignment and
                        minimizing backlash when calibrated accurately. Such
                        measures are essential for enhancing control over
                        transmission efficiency and can be crucial in
                        environments where space constraints necessitate noise
                        reduction techniques.
                      </p>
                    </div>
                    <div className="text-holder mb-3">
                      <h2>High-Speed Applications</h2>
                      <p>
                        Reducing noise levels in right-angle applications
                        typically centers around optimizing the meshing distance
                        between gears. This approach is particularly beneficial
                        for spiral bevel gears, ensuring precise alignment and
                        minimizing backlash when calibrated accurately. Such
                        measures are essential for enhancing control over
                        transmission efficiency and can be crucial in
                        environments where space constraints necessitate noise
                        reduction techniques.
                      </p>
                    </div>
                    <div className="text-overview mb-3">
                      <h4>Also Overview:</h4>
                      <h4 className="border-text">Pinion Gear</h4>
                      <h4>Sprocket Gear</h4>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div
                      className="form-heading my-md-5 my-sm-3 my-3"
                      ref={headingRef}
                    >
                      <h3> Enquiry</h3>
                    </div>
                  </Col>
                  <Col md={9}>
                    <div
                      className="enquiry-form enqiry-modal contact-us"
                      ref={formRef}
                    >
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="formGroupCountry"
                        >
                          <Controller
                            name="country_id" // name of the field
                            {...("country_id",
                            {
                              required: "Select Sub Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.country_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={country}
                              />
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formGroupFullName"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter Full Name*"
                            {...register("full_name", {
                              required: "Full Name is required",
                              maxLength: {
                                value: 50,
                                message:
                                  "Full Name cannot exceed 50 characters",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.full_name,
                            })}
                          />
                          {errors.full_name && (
                            <div className="invalid-feedback">
                              {errors.full_name.message}
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1 "
                        >
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="E-mail Address"
                            {...register("email", {
                              required: "Email Id required",
                              pattern: {
                                value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                message: "Email Id Invalid",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email,
                            })}
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email.message}
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPhone">
                          <Controller
                            name="mobile_no" // Field name for react-hook-form
                            control={control} // Control object from useForm
                            rules={{
                              required: "Mobile number is required",
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <PhoneInput
                                  {...field} // Spread field properties from react-hook-form
                                  country={"in"}
                                  enableSearch={true}
                                  value={field.value || ""} // Ensure value is controlled
                                  onChange={(value) => field.onChange(value)} // Update react-hook-form state
                                  buttonClass="phone-input-button"
                                />
                                {error && (
                                  <div className="invalid-feedback">
                                    {error.message}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formGroupCountry"
                        >
                          <Controller
                            name="country_id" // name of the field
                            {...register("country_id", {
                              required: "Select Sub Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.country_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={country}
                              />
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formGroupCountry"
                        >
                          <Controller
                            name="city_id" // name of the field
                            {...register("city_id", {
                              required: "Select City",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.city_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={cities}
                              />
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formGroupMessage"
                        >
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Write your message here"
                            {...register("message", {
                              required: "Message is required",
                              maxLength: {
                                value: 500,
                                message: "Message cannot exceed 500 characters",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.message,
                            })}
                          />
                          {errors.message && (
                            <div className="invalid-feedback">
                              {errors.message.message}
                            </div>
                          )}
                        </Form.Group>
                        <Row>
                          <Col md={3}></Col>
                          <Col md={9}>
                            {!showSuccessMessage && (
                              <div className="button-holder text-center my-4 ">
                                <CommanButton
                                  className={"blue-btn"}
                                  text={"Submit"}
                                  onClick={handleSubmit}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Form>
                      {showSuccessMessage && (
                        <div className="sent-message-text my-4 justify-content-center d-flex">
                          <SuccessLottey />
                          <p>Enquiry Generated Successfully</p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default ProductDetails;
