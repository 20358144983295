import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import {
  getData,
  postData,
  patchData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
  getDimension,
  putData,
} from "./api";
import { Per_Page_Dropdown, Select2Data, RequiredIs } from "./common";

export const Context = createContext();
const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

const AppContext = ({ children }) => {
  const IMG_URL = "http://46.28.44.87:8000/admin"; // Adjust this URL as needed
  // const IMG_URL = "http://127.0.0.1:8000/admin";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [isAllow, setisAllow] = useState([]);
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    validateTokenOnLoad();
  }, []);

  const validateTokenOnLoad = async () => {
    const token = Cookies.get("warri_sport_admin_security");

    if (token) {
      console.log("Token found:", token);
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode token
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp && decodedToken.exp < currentTime) {
          console.log("Token expired");
          logoutUser();
        } else {
          console.log("Token valid. Fetching user data...");
          await fetchUserData(decodedToken.user);
          setSignin(true);
          setUsertype(decodedToken.rolename || "");
          setisAllow(decodedToken.permissions || []);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        logoutUser();
      }
    } else {
      console.log("No token found. Redirecting to login.");
      // logoutUser();
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const response = await getData(`/login/${userId}`);
      if (response?.success) {
        console.log("User data fetched successfully:", response.data);
        setUserData(response?.data?.data || {});
        setisAllow(response?.data?.permissions || []);
      } else {
        console.error("Failed to fetch user data:", response?.message);
        logoutUser();
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      logoutUser();
    }
  };

  const logoutUser = () => {
    Cookies.remove("warri_sport_admin_security", { path: "/" });
    setSignin(false);
    setUsertype("");
    setUserData({});
    setisAllow([]);
    navigate("/"); // Redirect to login
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const ErrorNotify = (message) => {
    toast.error(`${message}`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        patchData,
        putData,
        editStatusData,
        deleteData,
        getDownloadDataExcel,
        minLength: 2,
        maxLength: 30,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        ErrorNotify,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RequiredIs,
        getDimension,
        isAllow,
        htmlToReactParser,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
