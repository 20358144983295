import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // To fetch the ID from the URL
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumComponent from '../../CommanComponent/BreadcrumComponent/BreadcrumComponent';
import FaqBanner from '../../Faq/FaqBanner/FaqBanner';
import './BlogDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { getblogdetails, getblogs } from '../../../utils/apis/master/Master';
import { Context } from '../../../utils/context';

library.add(fas);

const BlogDetails = () => {
    const { id } = useParams(); // Get the blog ID from the URL
    const { IMG_URL } = useContext(Context);
    const [blog, setBlog] = useState(null); // For the specific blog
    const [blogDetails, setBlogDetails] = useState(null); // For the specific blog
    const [error, setError] = useState(null); // To handle errors

    const fetchBlogById = async (blogId) => {
        try {
            const res = await getblogs(1, 10); // Adjust pagination if needed
            if (res?.success) {
                const matchedBlog = res.results.find((b) => b.id === parseInt(blogId));
                if (matchedBlog) {
                    setBlog(matchedBlog);
                } else {
                    setError("Blog not found.");
                }
            } else {
                setError(res?.error || "Error fetching data.");
            }
        } catch (err) {
            setError("Error fetching blog details.");
        }
    };


    const fetchBlogDetailsId = async (id) => {
        try {
            const res = await getblogdetails(id); // Adjust pagination if needed
            if (res?.success) {
                console.log(res?.results,'consolelofff');
                setBlogDetails(res?.results)
                
            } else {
                setError(res?.error || "Error fetching data.");
            }
        } catch (err) {
            setError("Error fetching blog details.");
        }
    };
    

    useEffect(() => {
        if (id) {
            fetchBlogById(id);
            fetchBlogDetailsId(id);
        }
    }, [id]);

    const breadcrumbItems = [
        { text: "HOME", link: "/" },
        { text: "BLOGS", link: "/blog" },
        { text: blog?.title || "BLOG DETAILS", link: `/blog-details/${id}`, className: "active" },
    ];

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    if (!blog) {
        return <p>Loading blog details...</p>;
    }

    return (
        <>
            <FaqBanner HeadingName={"Blog"} />
            <section className='blog-details'>
                <Container>
                    <Row>
                        <div className='mt-5'>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                        <Col md={8} className="d-flex justify-content-center">
                            <div className='image-holder'>
                            <img className="details-img" src={IMG_URL + blog.image} alt={blog.title} />
                            </div>
                            </Col>

                        <Col md={4} className='details-holder'>
                            <div className='text-holder'>
                                <h2>{blog.heading}</h2>
                            </div>
                            <div className='d-flex'>
                                <div className='icon-holder'>
                                     <FontAwesomeIcon icon={faCalendar} className='fa-icon' />
                                     <span>{new Date(blog.created_at).toLocaleDateString()}</span>
                                </div>
                                <div className='icon-holder ms-5'>
                                    <FontAwesomeIcon icon={faUser} className='fa-icon' />
                                    <span>{blog.author || "Admin"}</span>
                                </div>
                            </div>
                            <div className='view-details'>
                                {/* <div className='icon-holder'>
                                    <FontAwesomeIcon icon="fa-solid fa-eye" className='fa-icon' />
                                    <span>Post views: {blog.views || 0}</span>
                                </div> */}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className='paragraph-text mt-4'>
                                <p className='mb-5'>{ blog.description}</p>
                               
                                {blogDetails?.map((blog, index) => (
                                <div key={index}>
                                <h3>{blog.sub_heading}</h3>
                                <p>
                                    {blog.description }
                                </p>
                                </div>
                                ))}

                                
                                </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default BlogDetails;
