import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "react-phone-input-2/lib/bootstrap.css";
import "./EnquiryModal.css"; // Import the custom CSS
import CommanButton from "../../CommanButton/CommanButton";

import SuccessLottey from "../../SuccessLottey/SuccessLottey";

import { Col, Container, Row, Form } from "react-bootstrap";

import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import { Link } from "react-router-dom";
import {
  ContactusPost,
  CountryWithout,
  CityWithout,
} from "../../../../utils/apis/master/Master";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Controller, SubmitHandler } from "react-hook-form";
import { Select2Data } from "../../../../utils/common";

library.add(fas);

const EnquiryModal = (props) => {
  const [mobile_no, setPhone] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  console.log(countries, "lalalal");
  const [selectedCity, setSelectedCity] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [animate, setAnimate] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "#F3F3F3",
      borderRadius: "5px",
      fontSize: "14px",
      border: "none",
      height: "40px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
    }),
  };

  // Fetch countries and initialize state
  const fetchCountries = async () => {
    try {
      const response = await CountryWithout();
      if (response?.success) {
        setCountries(response?.results || []);
      } else {
        console.error("Error fetching countries:", response?.error);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  // Fetch cities based on selected country
  const fetchCities = async () => {
    const response = await CityWithout();

    if (response?.success) {
      setCities(await Select2Data(response?.results, "city_id"));
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedCity(null); // Clear selected city when country changes
    if (selectedOption?.value) {
      fetchCities(selectedOption.value); // Fetch cities for the selected country
    } else {
      setCities([]); // Clear cities if no country is selected
    }
  };

  const GetAllCountry = async (id) => {
    const response = await CountryWithout();

    if (response?.success) {
      setCountry(await Select2Data(response?.results, "country_id"));
    }
  };

  const onSubmit = async (data) => {
    // Transforming the payload to include only `value` for `country_id` and `city_id`
    const transformedData = {
      ...data,
      country_id: data.country_id?.value, // Extract `value` from country_id
      city_id: data.city_id?.value, // Extract `value` from city_id
    };

    const DataToSend = new FormData();
    DataToSend.append("country", transformedData.country_id);
    DataToSend.append("city", transformedData.city_id);
    DataToSend.append("full_name", data?.full_name);
    DataToSend.append("mobile_no", data?.mobile_no);
    DataToSend.append("message", data?.message);
    DataToSend.append("country_code", data?.country_code);

    console.log(transformedData, "Transformed Data");

    try {
      const res = await ContactusPost(transformedData); // Call your API method
      if (res?.success) {
        setShowSuccessMessage(true);
        // Show success message
        setTimeout(() => {
          setShowSuccessMessage(false); // Hide success message after 3 seconds
          // resetForm(); // Reset the form fields
          window.location.reload();
        }, 3000);
      } else {
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    fetchCountries(); // Fetch countries on component mount
    GetAllCountry();
    fetchCities();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="enqiry-modal contact-us"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Connect with Us
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupFullName">
              <Form.Control
                type="text"
                placeholder="Enter Full Name*"
                {...register("full_name", {
                  required: "Full Name is required",
                  maxLength: {
                    value: 50,
                    message: "Full Name cannot exceed 50 characters",
                  },
                })}
                className={classNames("", {
                  "is-invalid": errors?.full_name,
                })}
              />
              {errors.full_name && (
                <div className="invalid-feedback">
                  {errors.full_name.message}
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1 ">
              <Form.Control
                type="text"
                name="email"
                placeholder="E-mail Address"
                {...register("email", {
                  required: "Email Id required",
                  pattern: {
                    value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                    message: "Email Id Invalid",
                  },
                })}
                className={classNames("", {
                  "is-invalid": errors?.email,
                })}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPhone">
              <Controller
                name="mobile_no" // Field name for react-hook-form
                control={control} // Control object from useForm
                rules={{
                  required: "Mobile number is required",
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <PhoneInput
                      {...field} // Spread field properties from react-hook-form
                      country={"in"}
                      enableSearch={true}
                      value={field.value || ""} // Ensure value is controlled
                      onChange={(value) => field.onChange(value)} // Update react-hook-form state
                      buttonClass="phone-input-button"
                    />
                    {error && (
                      <div className="invalid-feedback">{error.message}</div>
                    )}
                  </>
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupCountry">
              <Controller
                name="country_id" // name of the field
                {...register("country_id", {
                  required: "Select Sub Category",
                })}
                control={control}
                render={({ field }) => (
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: errors.country_id ? "red" : baseStyles,
                      }),
                    }}
                    {...field}
                    options={country}
                  />
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupCountry">
              <Controller
                name="city_id" // name of the field
                {...register("city_id", {
                  required: "Select City",
                })}
                control={control}
                render={({ field }) => (
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: errors.city_id ? "red" : baseStyles,
                      }),
                    }}
                    {...field}
                    options={cities}
                  />
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupMessage">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Write your message here"
                {...register("message", {
                  required: "Message is required",
                  maxLength: {
                    value: 500,
                    message: "Message cannot exceed 500 characters",
                  },
                })}
                className={classNames("", {
                  "is-invalid": errors?.message,
                })}
              />
              {errors.message && (
                <div className="invalid-feedback">{errors.message.message}</div>
              )}
            </Form.Group>
            {!showSuccessMessage && (
              <div className="button-holder text-center my-md-5 my-sm-3 my-3">
                <CommanButton
                  className={"blue-btn"}
                  text={"Submit"}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            )}
          </Form>
          {showSuccessMessage && (
            <div className="sent-message-text my-5 justify-content-center d-flex">
              <SuccessLottey />
              <p>Enquiry Generated Successfully</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnquiryModal;
