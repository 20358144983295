import React, { useContext, useEffect, useState } from 'react';
import './Blog.css';
import FaqBanner from '../Faq/FaqBanner/FaqBanner';
import Pagination from 'react-bootstrap/Pagination';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { getblogs } from '../../utils/apis/master/Master';
import { Context } from '../../utils/context';

library.add(fas);

const Blog = () => {
    const { IMG_URL } = useContext(Context);
    const [blog, setBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPages, setPerPages] = useState(1);

 
    const getProjectTwo = async (currentPage,perPages) => {
        try {
          
            const res = await getblogs(currentPage,perPages);
            console.log('API Response:', res); // Debug log
            if (res?.success) {
                setBlog(res?.results);
                setCurrentPage(res?.currentPage || 1);
                setTotalPages(res?.totalPages || 1);
                setPerPages(res?.perPages || 1);
            } else {
                console.error("Error fetching data:", res?.error);
            }
        } catch (error) {
            console.error("Error fetching gallery data:", error);
        }
    };

    useEffect(() => {
        getProjectTwo(currentPage);  // Ensure that `currentPage` is passed to the API when component mounts
    }, [currentPage]);  // Re-fetch data when currentPage changes

    const handlePageChange = (page) => {
        console.log(`Navigating to page: ${page}`); // Debug log
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page); // Set the currentPage state
        }
    };

    return (
        <>
            <FaqBanner HeadingName={"Blog"} />
            <section className='blog'>
                <Container>
                    <Row>
                        <Col xxl={9} xl={8} lg={8} md={8}>
                            <div className='top-heading mb-4'>
                                <h2>Top Updates</h2>
                            </div>
                            <Row>
                                {blog?.map((blog, index) => (
                                    <Col xxl={4} xl={4} lg={6} md={6} sm={6} key={index}>
                                        <Link to={`/blog-details/${blog.id}`}>
                                            <div className='update-card mb-5'>
                                                <div className='update-img'>
                                                    <img className="blog-update-img" src={IMG_URL + blog.image} alt={blog.title} />
                                                </div>
                                                <div className='heading-text'>
                                                    <h3>{blog.heading}</h3>
                                                    <p>{blog.description}</p>
                                                    <span className="readmore-p">              Read More </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={4}>
                            <div className='top-heading mb-4'>
                                <h2>Recent Updates</h2>
                            </div>
                            {blog?.map((blog, index) => (
                                <div className='recent-post-card mb-2' key={index}>
                                    <Row>
                                        <Col lg={7}>
                                            <div className='recent-img'>
                                                <img className="blog-recent-img" src={IMG_URL + blog.image} alt={blog.heading} />
                                            </div>
                                        </Col>
                                        <Col lg={5}>
                                            <div className='date-holder'>
                                                <div className='icon-holder'>
                                                    <FontAwesomeIcon icon={faCalendar} className='fa-icon' />
                                                    <span>{new Date(blog.created_at).toLocaleDateString()}</span>
                                                </div>
                                                <div className='icon-holder'>
                                                    <FontAwesomeIcon icon={faUser} className='fa-icon' />
                                                    <span>Admin</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <h3>{blog.heading}</h3>
                                        <p>{blog.description}</p>
                                    </Row>
                                </div>
                            ))}
                        </Col>
                        <Col lg={12}>
                            <div className='pagination-container'>
                                <Pagination>
                                    <Pagination.Prev
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Prev
                                    </Pagination.Prev>
                                    {[...Array(totalPages)].map((_, idx) => (
                                        <Pagination.Item
                                            key={idx + 1}
                                            active={idx + 1 === currentPage}
                                            onClick={() => handlePageChange(idx + 1)}
                                        >
                                            {idx + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Blog;
