import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Attributes = async () => {
  try {
    return await getData(`/all-attribute`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/allcountry`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/allstates/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/allcity/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const PincodeByCity = async (id) => {
  try {
    return await getData(`/allpincode/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (id) => {
  try {
    return await getData(`/allpincode`);
  } catch (error) {
    console.log(error);
  }
};

export const CountryCode = async () => {
  try {
    return await getData(`/allcountrycode`);
  } catch (error) {
    console.log(error);
  }
};

export const Category = async () => {
  try {
    return await getData(`/allcategories`);
  } catch (error) {
    console.log(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/allsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildCategory = async (id) => {
  try {
    return await getData(`/allchildcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/allchildsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Brand = async () => {
  try {
    return await getData(`/allbrands`);
  } catch (error) {
    console.log(error);
  }
};

export const Area = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-area`);
  } catch (error) {
    console.log(error);
  }
};

export const FaqCategory = async () => {
  try {
    return await getData(`/allFaqCategories`);
  } catch (error) {
    console.log(error);
  }
};

export const Faq = async (id) => {
  try {
    return await getData(`/allFaq/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Days = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-days`);
  } catch (error) {
    console.log(error);
  }
};

export const FirmType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-firm-type`);
  } catch (error) {
    console.log(error);
  }
};

export const GST = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-gst`);
  } catch (error) {
    console.log(error);
  }
};

export const OrderStatus = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-order-status`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductInfo = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-product-info`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceTerm = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-service-term`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-service-type`);
  } catch (error) {
    console.log(error);
  }
};

export const Unit = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-unit`);
  } catch (error) {
    console.log(error);
  }
};

export const VarientType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-varient-type`);
  } catch (error) {
    console.log(error);
  }
};

export const HelpCategory = async () => {
  try {
    return await getData(`/all-help-category`);
  } catch (error) {
    console.log(error);
  }
};

export const getpincodeLatLong = async (pincode) => {
  try {
    return await getData(`/lat-long?pincode=${pincode}`);
  } catch (error) {
    console.log(error);
  }
};

export const getgallery = async (currentPage,perPages) => {
  try {
    return await getData(`/gallery/app/apis/gallery/?page=${currentPage}&per_page=${perPages}`);
  } catch (error) {
    console.log(error);
  }
};

export const getblogs = async (currentPage,perPages) => {
  try {
    return await getData(`/blog/app/apis/blog/?page=${currentPage}&per_page=${perPages}`);
  } catch (error) {
    console.log(error);
  }
};


export const getblogdetails = async (id) => {
  try {
    return await getData(`/blog/app/apis/blog-details-id/${id}/`);
  } catch (error) {
    console.log(error);
  }
};

export const CountryWithout = async () => {
  try {
    return await getData(`/contactus/app/apis/country-without/`);
  } catch (error) {
    console.log(error);
  }
};

export const CityWithout = async () => {
  try {
    return await getData(`/contactus/app/apis/city-without/`);
  } catch (error) {
    console.log(error);
  }
};

export const ContactusPost = async (data) => {
  try {
    return await postData(`/contactus/app/apis/contact-us/`,data);
  } catch (error) {
    console.log(error);
  }
};

